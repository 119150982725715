<template>
    <section class="content">
        <div class="card card-primary">
            <div class="card-header">
                <h3 class="card-title">Informasi Akun</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form" id="form_user" @submit="submitForm">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="alert fade show" role="alert" v-if="infoVerifikasi" 
                                :style="[ { cursor : !disableVerifikasi ? 'pointer' : 'default' }, styleVerifikasi ]"
                                @click="!disableVerifikasi ? $router.push('/verification') : {}">
                                <i class="fi fi-rr-exclamation"></i> {{ infoVerifikasi }}
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Username</label>
                                <input type="text" value="" class="form-control" v-model="form.username" placeholder="Nama" readonly />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Nama</label>
                                <input type="text" value="" class="form-control" v-model="form.name" placeholder="Nama" 
                                onkeydown="return (event.ctrlKey || event.altKey 
                                || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                                || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                                || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91) )"/>
                                <input type="hidden" v-model="form.id" id="i_id" value="" name="i_id" />
                            </div>
                            <div class="form-group" @click="loadOTP">
                                <label for="exampleInputEmail1">No Telepon</label>
                                <input  type="number" value="" class="form-control" v-model="form.tel" placeholder="Nomor Telepon" />
                                <div v-if="form.verified_number == 't'" style="padding-top:10px; color:green;">Nomor Terverifikasi</div>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email</label>
                                <input type="email" value="" class="form-control" v-model="form.email" placeholder="Alamat Email" readonly />
                                <div v-if="form.verified_email == '1'" style="padding-top:10px; color:green;">Email Terverifikasi</div>
                            </div>
                            <div class="form-group d-none">
                                <label for="exampleInputEmail1">Bahasa</label>
                                <v-select class="form-controlx" :options="languages" :reduce="(opt) => opt.code" v-model="form.selected"></v-select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="
                  card-body
                  p-1
                  user-profile
                  <?=$user->image_id?'':'empty-profile'?>
                ">
                                <img style="object-fit: cover; height: 250px; width: 100%" alt="image" id="imgPreview" class="img-fluid img-responsive" src="http://localhost/boscod/app/backend/api/uploads/default/unknown_user.jpg" />
                                <div id="loading-avatar" class="center-profile" style="display: none">
                                    <div class="btn-profile">
                                        <div class="spinner-border spinner-border-sm" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div id="remove-avatar" class="center-profile">
                                    <a id="del-avatar" class="btn-profile remove-profile text-danger" title="Hapus Foto" @click="removeAvatar"><i class="fa fa-times"></i></a>
                                </div>
                            </div>
                            <input name="file" type="file" id="inputFile" class="inputfile" @change="uploadFoto(this)" />
                            <label for="file" class="btn btn-block btn-success" @click="browsefile()"><i class="fa fa-image"></i> Pilih Foto</label>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label for="exampleInputPassword1">Password Lama</label>
                            <div class="input-group">
                                <input type="password" class="form-control" name="i_pwd" placeholder="Password" data-toggle="password" v-model="form.oldpass" minlength="8" />
                                <div class="input-group-append">
                                    <span class="input-group-text input-password-hide" style="cursor: pointer;">
                                        <i class="fa fa-eye"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-6"></div>
                        <div class="form-group col-md-6">
                            <label for="exampleInputPassword1">Password Baru</label>
                            <div class="input-group">
                                <input type="password" class="form-control" name="i_pwd" placeholder="Password" data-toggle="password" v-model="form.newpass" minlength="8" />
                                <div class="input-group-append">
                                    <span class="input-group-text input-password-hide" style="cursor: pointer;">
                                        <i class="fa fa-eye"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="progress rounded-pill mt-2" v-if="true">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" :class="[passClass]" role="progressbar" v-bind:aria-valuenow="passStat" aria-valuemin="0" aria-valuemax="100" :style="{ 'width': passStat + '%' }"></div>
                            </div>
                            <label class="d-block text-left font-weight-normal mt-1" style="color:#62676F;" v-html="passMsg"></label>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="exampleInputPassword1">Konfirmasi Password Baru</label>
                            <div class="input-group">
                                <input type="password" class="form-control" name="i_pwdconfirm" placeholder="Konfirmasi Password" data-toggle="password" v-model="form.newpassc" minlength="8" />
                                <div class="input-group-append">
                                    <span class="input-group-text input-password-hide" style="cursor: pointer;">
                                        <i class="fa fa-eye"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /.box-body -->
                <div class="card-footer form-actions">
                    <button type="submit" id="tb_save" data-loading-text="Simpan..." class="btn btn-primary">
                        <i class="fa fa-pen"></i> Simpan
                    </button>
                </div>
            </form>
        </div>
        <NewNumber v-on:change="handleCustomChange" v-bind:params="{
          phoneNumber: form.tel,
          otpType: formNewNumber.otpType,
          countShowOTP: countShowOTP,
          shipper: form.id,
        }" />
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth } from "@/libs/hxcore";
import $ from "jquery";
import Swal from 'sweetalert2';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import NewNumber from "@/dialog/NewNumber";
export default {
  name: "Profile",
  data() {
    return {
      languages: [{ label: "Indonesia", code: "id" }],
      form: {
        selected: "id",
        userId: "",
        name: "",
        username: "",
        email: "",
        tel: "",
        id: "",
        oldpass: "",
        newpass: "",
        newpassc: "",
        verified_number: "",
        verified_email: "",
      },
      infoVerifikasi: "",
      styleVerifikasi: {},
      disableVerifikasi: true,
      passClass: 'bg-danger',
      passStat: 0,
      passMsg: '',
      formNewNumber:{
        code: '',
			  otpType: ''
      },
      countShowOTP: 0,
    };
  },
  components: {
    vSelect,
    NewNumber
  },
  computed: {
      newpass: function () {
          return this.form.newpass;
      },
  },
  watch: {
      newpass: function (val) {
          if (val.length < 8) {
              this.passClass = 'bg-danger';
              this.passStat = 0;
              this.passMsg = '<span class="text-danger">Password minimal 8 karakter</span>';
          } else if (!(/[a-z]/.test(val) && /[A-Z]/.test(val))) {
              this.passClass = 'bg-danger';
              this.passStat = 25;
              this.passMsg = '<b>Kekuatan: <span class="text-danger">Lemah</span>.</b> Setidaknya memuat minimal 1 huruf ' + (/[a-z]/.test(val) ? 'besar' : 'kecil');
          } else if (!(/\d/.test(val))) {
              this.passClass = 'bg-yellow';
              this.passStat = 50;
              this.passMsg = '<b>Kekuatan: <span class="text-yellow">Cukup</span>.</b> Setidaknya memuat minimal 1 angka';
          } else if (!(/[^a-zA-Z 0-9]+/.test(val))) {
              this.passClass = 'bg-info';
              this.passStat = 75;
              this.passMsg = '<b>Kekuatan: <span class="text-info">Bagus</span>.</b> Tambahkan minimal 1 karakter spesial';
          } else {
              this.passClass = 'bg-success';
              this.passStat = 100;
              this.passMsg = '<b>Kekuatan: <span class="text-success">Sempurna</span>.</b> Kamu punya password yang kuat';
          }
      }
  },
  created: function () {
    authFetch(`/users/profile`).then((res) => {
      res.json().then((json) => {
        this.form.name = json.display_name;
        this.form.email = json.email;
        this.form.verified_number = json.verified_number;
        this.form.verified_email = json.verified_email;
        if (json.role_id == 9) {
          this.form.tel = json.phone;
        } else if (json.role_id == 11) {
          this.form.tel = json.kontak_hp;
        }
        this.form.id = json.id;
        this.form.username = json.username;

        if (json.file_path) {
          $("#imgPreview").attr(
            "src",
            json.image_url
          );
        }

        if (json.ktp_verify == '0') {
            this.infoVerifikasi = 'Lakukan verifikasi data agar kamu dapat melakukan pencairan dana';
            this.styleVerifikasi = {color: '#721c24', backgroundColor: '#f8d7da', borderColor: '#721c24'};
            this.disableVerifikasi = false;
        } else if (json.ktp_verify == '1') {
            this.infoVerifikasi = 'Data kamu sudah terverifikasi';
            this.styleVerifikasi = {color: '#155724', backgroundColor: '#d4edda', borderColor: '#155724'};
            this.disableVerifikasi = true;
        } else if (json.ktp_verify == '2') {
            this.infoVerifikasi = 'Data kamu sedang diproses';
            this.styleVerifikasi = {color: '#856404', backgroundColor: '#fff3cd', borderColor: '#856404'};
            this.disableVerifikasi = false;
        } else if (json.ktp_verify == '3') {
            this.infoVerifikasi = 'Cek dokumen dan lakukan verifikasi ulang';
            this.styleVerifikasi = {color: '#721c24', backgroundColor: '#f8d7da', borderColor: '#721c24'};
            this.disableVerifikasi = false;
        }
        $('.loading-overlay').removeClass('show');
      });
    });
  },
  methods: {
    submitForm: function (ev) {
      const e = this.$refs;
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/users/profile";
      if (this.form.id) {
        this.method = "PUT";
        urlSubmit = "/users/profile/" + this.form.id;
      } else {
        this.method = "POST";
      }

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (js.success) {
            Swal.fire({
              title: 'Update Berhasil',
              icon: 'success',
              text: 'Data profil sudah diudate',
              showCloseButton: true
            })
          }
          else{
            Swal.fire({
              title: 'Update Gagal',
              icon: 'error',
              text: js.message,
              showCloseButton: true
            })
          }
        });

      ev.preventDefault();
    },
    browsefile: function (argument) {
      document.getElementById("inputFile").click();
    },
    uploadFoto: function (file) {
      var info = auth.user();
      var file_data = $("#inputFile").prop("files");
      var form_data = new FormData();
      var fileInput = $("#inputFile")[0];
      this.readURL(fileInput);
      $.each(fileInput.files, function (k, file) {
        form_data.append("file", file);
      });
      $("#loading-avatar").show();
      form_data.append("subdir", "/profile_upload/" + info.id);
      form_data.append("id", info.id);

      authFetch("/users/profile/upload_data", {
        method: "POST",
        body: form_data,
        headers: {
          "Content-Type": null,
        },
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.status) {

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          $("#loading-avatar").hide();
        });
    },
    readURL: function (input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $("#imgPreview").attr("src", e.target.result);
            };

            reader.readAsDataURL(input.files[0]); // convert to base64 string
        }
    },
    removeAvatar: function () {
        var info = auth.user();
        authFetch("/users/profile/remove_profile/" + info.id, {
            method: "POST",
            headers: {
              "Content-Type": null,
            },
        })
        .then((res) => {
            if (res.status === 201) {
                
            } else if (res.status === 400) 
            {
                
            }
          return res.json();
        })
        .then((js) => {
          $("#img_id").val("");
          $("#img_code").val("");
          $("#imgPreview").attr(
            "src",
            window.$apiUrl+"/uploads/default/unknown_user.jpg"
          );
          $(".user-profile").addClass("empty-profile");
        });
    },
    handleCustomChange (s) {
      this.form.code = s;
      // this.postData();
      // this.submitForm();
    },
    loadOTP: function (ev) {
      $("#update").prop("disabled", true);
      $("#submit").prop("disabled", true);
      this.formNewNumber.otpType = 'change_rekening';
      this.countShowOTP += 1;
      ev.preventDefault();
    },
    closeOPT: function (ev) {
      this.form.otpType = 'change_rekening';
      // this.showOTP = true;
      this.countShowOTP = 0;
      ev.preventDefault();
    },
  },
  mounted() {},
};

$(function () {
	$('[data-toggle="password"]').each(function () {
		var input = $(this);
		var eye_btn = $(this)
			.parent()
			.find(".input-group-text");
		eye_btn.css("cursor", "pointer").addClass("input-password-hide");
		eye_btn.on("click", function () {
			if (eye_btn.hasClass("input-password-hide")) {
				eye_btn
					.removeClass("input-password-hide")
					.addClass("input-password-show");
				eye_btn
					.find(".fa")
					.removeClass("fa-eye")
					.addClass("fa-eye-slash");
				input.attr("type", "text");
			} else {
				eye_btn
					.removeClass("input-password-show")
					.addClass("input-password-hide");
				eye_btn
					.find(".fa")
					.removeClass("fa-eye-slash")
					.addClass("fa-eye");
				input.attr("type", "password");
			}
		});
	});
	setInterval(function () {
		$('[data-slide="next"]').click();
	}, 5000);
});
</script>
<style type="text/css">
.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile+label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    /*background-color: black;*/
    display: inline-block;
}

.inputfile:focus+label,
.inputfile+label:hover {
    /*background-color: red;*/
}

.inputfile+label {
    cursor: pointer;
    /* "hand" cursor */
}

.inputfile:focus+label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.user-profile .center-profile {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    transition: opacity 0.3s;
    line-height: 34px;
    text-align: center;
    text-decoration: none;
}

.user-profile .btn-profile {
    display: block;
    width: 36px;
    height: 36px;
    transition: opacity 0.3s;
    border-radius: 50%;
    background-color: #fff;
    color: #606975;
    line-height: 34px;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
    cursor: pointer;
}

.user-profile .remove-profile {
    /*visibility: hidden !important;*/
    cursor: pointer;
}
</style>